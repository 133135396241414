import {
  faUser,
  faTags,
  faBagShopping,
  faClockRotateLeft,
  faHeadset,
  faBug,
  faArrowRightFromBracket,
  faUserPlus,
  faBattery,
} from "@fortawesome/free-solid-svg-icons";
import {
  useDisclosure,
  ModalContent,
  ModalHeader,
  Avatar,
  ModalBody,
  Listbox,
  ListboxItem,
  Link,
} from "@heroui/react";
import { ReactNode, useCallback } from "react";
import {
  Config,
  Drawer,
  EnvironmentVariables,
  FontAwesomeSvgIcon,
  useStore,
} from "../../../../core/core";
import { useLogOut } from "../../../auth/view/LogOut";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";

export function useAppDrawer(props: {
  openProfileClicked: () => void;
  openMyBatteryClicked: () => void;
  onPromoCodeClicked: () => void;
  onReferralClicked: () => void;
  onPaymentHistoryClicked: () => void;
  onBuySparkClicked: () => void;
  onSupportClicked: () => void;
}): {
  openDrawer: () => void;
  logOutModal: ReactNode;
  drawerModal: ReactNode;
} {
  const [logOutModal, onLogOut] = useLogOut();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const profileState = useStore((state) => state.profile)!;

  const openDrawer = useCallback(() => {
    logAnalyticsEvent(AnalyticsEvents.home.drawerButtonClicked);
    onOpen();
  }, [onOpen]);

  return {
    openDrawer,
    logOutModal,
    drawerModal: (
      <Drawer isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          <ModalHeader className="px-4">
            <div className="flex flex-row items-center">
              <Avatar
                src={"/avatars/" + profileState?.avatar}
                className="ml-4 cursor-pointer"
                size="lg"
                showFallback
              />
              <div className="flex flex-col">
                <h1 className="text-xl font-medium mb-1">
                  {profileState?.nickname && profileState?.nickname.length != 0
                    ? profileState?.nickname
                    : `${profileState?.fname} ${profileState?.lname ?? ""}`}
                </h1>
                <h2 className="text-sm font-normal">{`0${profileState?.phone.substring(
                  2
                )}`}</h2>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="px-4">
            <Listbox
              variant="flat"
              itemClasses={{
                title: "text-base mr-1",
              }}
              aria-label="Menu"
              onAction={onClose}
            >
              {...[
                <ListboxItem
                  key="profile"
                  startContent={<FontAwesomeSvgIcon icon={faUser} size="lg" />}
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.profileButtonClicked
                    );
                    props.openProfileClicked();
                  }}
                >
                  پروفایل من
                </ListboxItem>,
                <ListboxItem
                  key="battery"
                  startContent={
                    <FontAwesomeSvgIcon icon={faBattery} size="lg" />
                  }
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.myBatteryButtonClicked
                    );
                    props.openMyBatteryClicked();
                  }}
                >
                  باتری من
                </ListboxItem>,
                !EnvironmentVariables.isPreLaunch && (
                  <ListboxItem
                    key="promo-code"
                    startContent={
                      <FontAwesomeSvgIcon icon={faTags} size="lg" />
                    }
                    onPress={() => {
                      logAnalyticsEvent(
                        AnalyticsEvents.appDrawer.promoCodeButtonClicked
                      );
                      props.onPromoCodeClicked();
                    }}
                  >
                    کد تخفیف من
                  </ListboxItem>
                ),
                <ListboxItem
                  key="invite"
                  startContent={
                    <FontAwesomeSvgIcon icon={faUserPlus} size="lg" />
                  }
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.inviteButtonClicked
                    );
                    props.onReferralClicked();
                  }}
                >
                  دعوت دوستان
                </ListboxItem>,
                !EnvironmentVariables.isPreLaunch && (
                  <ListboxItem
                    key="payment-plans"
                    startContent={
                      <FontAwesomeSvgIcon icon={faBagShopping} size="lg" />
                    }
                    onPress={() => {
                      logAnalyticsEvent(
                        AnalyticsEvents.appDrawer.buySparkButtonClicked
                      );
                      props.onBuySparkClicked();
                    }}
                  >
                    خرید اشتراک
                  </ListboxItem>
                ),
                !EnvironmentVariables.isPreLaunch && (
                  <ListboxItem
                    key="payment-history"
                    startContent={
                      <FontAwesomeSvgIcon icon={faClockRotateLeft} size="lg" />
                    }
                    onPress={() => {
                      logAnalyticsEvent(
                        AnalyticsEvents.appDrawer.paymentHistoryButtonClicked
                      );
                      props.onPaymentHistoryClicked();
                    }}
                  >
                    تاریخچه خریدها
                  </ListboxItem>
                ),
                <ListboxItem
                  key="support"
                  startContent={
                    <FontAwesomeSvgIcon icon={faHeadset} size="lg" />
                  }
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.supportButtonClicked
                    );
                    props.onSupportClicked();
                  }}
                >
                  پشتیبانی
                </ListboxItem>,
                <ListboxItem
                  key="feature-request"
                  startContent={
                    <FontAwesomeSvgIcon icon={faLightbulb} size="lg" />
                  }
                  href={Config.featureRequestUrl}
                  target="_blank"
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.featureRequestButtonClicked
                    );
                  }}
                >
                  پشنهادات و انتقادات
                </ListboxItem>,
                <ListboxItem
                  key="report-bug"
                  showDivider
                  startContent={<FontAwesomeSvgIcon icon={faBug} size="lg" />}
                  href={Config.bugReportUrl}
                  target="_blank"
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.reportBugButtonClicked
                    );
                  }}
                >
                  گزارش مشکلات
                </ListboxItem>,
                <ListboxItem
                  key="log-out"
                  className="text-danger"
                  color="danger"
                  startContent={
                    <FontAwesomeSvgIcon
                      icon={faArrowRightFromBracket}
                      size="lg"
                    />
                  }
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.logOutButtonClicked
                    );
                    onLogOut();
                  }}
                >
                  خروج از حساب کاربری
                </ListboxItem>,
              ].filter((e) => !!e)}
            </Listbox>

            <div className="flex-grow flex flex-col items-center justify-end gap-4 mb-4">
              <h1 className="text-sm">ما را در شبکه‌های اجتماعی دنبال کنید!</h1>
              <div className="flex flex-row justify-center items-center gap-6">
                <Link
                  color="foreground"
                  href={Config.telegramUrl}
                  isExternal
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.telegramButtonClicked
                    );
                  }}
                >
                  <FontAwesomeSvgIcon icon={faTelegram} size="2xl" />
                </Link>

                {/* <Link
                  color="foreground"
                  href={Config.youtubeUrl}
                  isExternal
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.youtubeButtonClicked
                    );
                  }}
                >
                  <FontAwesomeSvgIcon icon={faYoutube} size="2xl" />
                </Link>
                <Link
                  color="foreground"
                  href={Config.xUrl}
                  isExternal
                  onPress={() => {
                    logAnalyticsEvent(AnalyticsEvents.appDrawer.xButtonClicked);
                  }}
                >
                  <FontAwesomeSvgIcon icon={faSquareXTwitter} size="2xl" />
                </Link>
                <Link
                  color="foreground"
                  href={Config.instagramUrl}
                  isExternal
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.appDrawer.instagramButtonClicked
                    );
                  }}
                >
                  <FontAwesomeSvgIcon icon={faInstagram} size="2xl" />
                </Link> */}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Drawer>
    ),
  };
}
