import { DomainFailure, RemoteDataSource, Result } from "../../../core/core";
import {
  ActiveSubscription,
  CreatePaymentRequest,
  Discount,
  Order,
  PaymentResultSummary,
  SubscriptionPlan,
} from "./entities/entities";

export class PaymentRemoteDataSource extends RemoteDataSource {
  async getSubscriptions(): Promise<Result<DomainFailure, SubscriptionPlan[]>> {
    const result = await this.httpClient.get("/subscription");

    return result.map((data) => data as SubscriptionPlan[]);
  }

  async searchDiscount(code: string): Promise<Result<DomainFailure, Discount>> {
    const result = await this.httpClient.get("/discount/search", {
      queryParameters: { code },
    });

    return result.map((data) => data as Discount);
  }

  async getSubscriptionHistory(): Promise<Result<DomainFailure, Order[]>> {
    const result = await this.httpClient.get("/subscription/history");

    return result.map((data) => data as Order[]);
  }

  async getActiveSubscription(): Promise<
    Result<DomainFailure, ActiveSubscription>
  > {
    const result = await this.httpClient.get("/subscription/active");

    return result.map((data) => data as ActiveSubscription);
  }

  // Returns the payment url
  async createPayment(
    request: CreatePaymentRequest
  ): Promise<Result<DomainFailure, string>> {
    const result = await this.httpClient.post("/payment", {
      body: request,
    });

    return result.map((data) => data["url"] as string);
  }

  async getPurchasedPlanSummary(
    paymentId: string
  ): Promise<Result<DomainFailure, PaymentResultSummary>> {
    const result = await this.httpClient.get(
      `/subscription/summary/${paymentId}`
    );

    return result.map((data) => data as PaymentResultSummary);
  }
}
