import { Button, Chip, Spinner } from "@heroui/react";
import {
  copyTextToClipboard,
  FailureView,
  FontAwesomeSvgIcon,
  paymentRepository,
  toPersianDigits,
  toPersianPrice,
  useAsyncValue,
} from "../../../../core/core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { PaymentHistoryEmptyState } from "./components/PaymentHistoryEmptyState";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";

export function PaymentHistory() {
  const [state, asyncValueController] = useAsyncValue({
    getData: useCallback(() => paymentRepository.getSubscriptionHistory(), []),
  });

  useEffect(() => {
    asyncValueController.invoke(null);
  }, [asyncValueController]);

  const copyFollowUpCode = useCallback((orderId: string) => {
    copyTextToClipboard(orderId);

    toast.info("کد پیگیری کپی شد.");
  }, []);

  return (
    <>
      {state.maybeFold({
        orElse: () => <Spinner />,
        loadFailure: (failure) => (
          <FailureView
            failure={failure}
            onRetry={() => asyncValueController.invoke(null)}
          />
        ),
        loadSuccess: (orders) => (
          <>
            {orders.length === 0 ? (
              <PaymentHistoryEmptyState />
            ) : (
              orders.map((order) => {
                return (
                  <div
                    key={order.id}
                    className="border-1 rounded-lg p-3 border-gray-300"
                  >
                    <div className="flex flex-row justify-between items-start">
                      <h1>
                        خرید{" "}
                        <span className="font-medium text-sm">
                          {order.subscription.name}{" "}
                          {toPersianDigits(
                            order.subscription.duration.toString()
                          )}{" "}
                          روزه
                        </span>
                        <br />
                        در تاریخ{" "}
                        {new Intl.DateTimeFormat("fa-IR", {
                          dateStyle: "medium",
                          timeStyle: "short",
                        }).format(order.createdAt)}
                      </h1>

                      <Chip
                        color={
                          order.status === "Failed" ||
                          order.status === "Expired"
                            ? "danger"
                            : order.status === "Pending"
                            ? "warning"
                            : "success"
                        }
                      >
                        {order.status === "Failed" || order.status === "Expired"
                          ? "ناموفق"
                          : order.status === "Pending"
                          ? "در انتظار پرداخت"
                          : "موفق"}
                      </Chip>
                    </div>

                    <div className="py-2">
                      {order.realPrice !== order.endPrice &&
                        order.status !== "Expired" &&
                        order.status !== "Failed" && (
                          <>
                            <div className="flex flex-row justify-between py-1">
                              <h1 className="font-medium">قیمت اصلی</h1>
                              <h2>
                                {toPersianPrice(
                                  (order.realPrice / 10).toString()
                                )}{" "}
                                تومان
                              </h2>
                            </div>
                            <div className="flex flex-row justify-between py-1">
                              <h1 className="font-medium text-danger">
                                سود شما از این خرید
                              </h1>
                              <h2 className="text-danger">
                                (
                                {toPersianDigits(
                                  ((1 - order.endPrice / order.realPrice) * 100)
                                    .toFixed(1)
                                    .replace(".0", "")
                                )}
                                ٪){" "}
                                {toPersianPrice(
                                  (
                                    (order.realPrice - order.endPrice) /
                                    10
                                  ).toString()
                                )}{" "}
                                تومان
                              </h2>
                            </div>
                          </>
                        )}
                      <div className="flex flex-row justify-between py-1.5">
                        <h1 className="font-medium">مبلغ پرداختی</h1>
                        <h2>
                          {toPersianPrice((order.endPrice / 10).toString())}{" "}
                          تومان
                        </h2>
                      </div>
                    </div>

                    <div className="flex flex-col items-stretch gap-2 pt-1">
                      <h1 className="font-medium">کد پیگیری</h1>
                      <div className="flex flex-row justify-between py-1 px-2 items-center bg-gray-100 rounded-[8px] ">
                        <Button
                          isIconOnly
                          size="sm"
                          variant="light"
                          className="ml-2"
                          onPress={() => {
                            logAnalyticsEvent(
                              AnalyticsEvents.paymentHistory.copyOrderIdClicked,
                              {
                                order_id: order.id,
                              }
                            );
                            copyFollowUpCode(order.orderId);
                          }}
                        >
                          <FontAwesomeSvgIcon icon={faCopy} size="xl" />
                        </Button>
                        <h2
                          className="font-mono text-xs line-clamp-1 text-left"
                          style={{ direction: "ltr" }}
                        >
                          {order.orderId}
                        </h2>
                      </div>
                    </div>
                    {order.status === "Pending" && order.url && (
                      <Button
                        fullWidth
                        variant="solid"
                        color="primary"
                        className="mt-2"
                        onPress={() => {
                          logAnalyticsEvent(
                            AnalyticsEvents.paymentHistory.payButtonClicked,
                            {
                              order_id: order.id,
                            }
                          );
                          window.open(order.url, "_self");
                        }}
                      >
                        پرداخت
                      </Button>
                    )}
                  </div>
                );
              })
            )}
          </>
        ),
      })}
    </>
  );
}
