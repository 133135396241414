class JoinEvents {
  readonly joinButtonClicked = "join.join_button_clicked";
}

class PhoneEvents {
  readonly submitButtonClicked = "phone.submit_button_clicked";
  readonly termsButtonClicked = "phone.terms_button_clicked";
  readonly privacyButtonClicked = "phone.privacy_button_clicked";
}

class OtpEvents {
  readonly submitButtonClicked = "otp.submit_button_clicked";
  readonly resendCodeButtonClicked = "otp.resend_code_button_clicked";
}

class RegistrationEvents {
  readonly avatarClicked = "registration.avatar_clicked";
  /**
   * @param avatar
   */
  readonly avatarSelected = "registration.avatar_selected";
  readonly firstNameInputClicked = "registration.first_name_input_clicked";
  readonly lastNameInputClicked = "registration.name_input_clicked";
  readonly nicknameInputClicked = "registration.nickname_input_clicked";
  /**
   * @param gender
   */
  readonly genderSelected = "registration.gender_selected";
  readonly birthdayClicked = "registration.birthday_clicked";
  readonly birthdaySelected = "registration.birthday_selected";
  readonly referralSourceClicked = "registration.referral_source_clicked";
  /**
   * @param referral_source
   */
  readonly referralSourceSelected = "registration.referral_source_selected";
  readonly submitRegistrationButtonClicked =
    "registration.submit_button_clicked";
  readonly registrationConfirmationDialogConfirmButtonClicked =
    "registration.confirmation_dialog_confirm_button_clicked";
  readonly registrationConfirmationDialogRecheckButtonClicked =
    "registration.confirmation_dialog_recheck_button_clicked";
}

class HomeEvents {
  readonly newChatButtonClicked = "home.new_chat_button_clicked";
  readonly drawerButtonClicked = "home.drawer_button_clicked";
  readonly batteryClicked = "home.battery_clicked";
  readonly starsBadgeClicked = "home.stars_badge_clicked";
  readonly walletBadgeBuySparkButtonClicked =
    "home.wallet_badge_buy_spark_button_clicked";
}

class AppDrawerEvents {
  readonly profileButtonClicked = "drawer.profile_button_clicked";
  readonly myBatteryButtonClicked = "drawer.my_battery_button_clicked";
  readonly promoCodeButtonClicked = "drawer.promo_code_button_clicked";
  readonly inviteButtonClicked = "drawer.invite_button_clicked";
  readonly buySparkButtonClicked = "drawer.buy_spark_button_clicked";
  readonly paymentHistoryButtonClicked =
    "drawer.payment_history_button_clicked";
  readonly supportButtonClicked = "drawer.support_button_clicked";
  readonly featureRequestButtonClicked =
    "drawer.feature_request_button_clicked";
  readonly reportBugButtonClicked = "drawer.report_bug_button_clicked";
  readonly logOutButtonClicked = "drawer.log_out_button_clicked";
  readonly instagramButtonClicked = "drawer.instagram_button_clicked";
  readonly xButtonClicked = "drawer.x_button_clicked";
  readonly youtubeButtonClicked = "drawer.youtube_button_clicked";
  readonly telegramButtonClicked = "drawer.telegram_button_clicked";
}

class ProfileEvents {
  readonly avatarClicked = "profile.avatar_clicked";
  /**
   * @param avatar
   */
  readonly avatarSelected = "profile.avatar_selected";
  readonly avatarRemoved = "profile.avatar_removed";
  readonly firstNameInputClicked = "profile.first_name_input_clicked";
  readonly lastNameInputClicked = "profile.name_input_clicked";
  readonly nicknameInputClicked = "profile.nickname_input_clicked";
  readonly submitButtonClicked = "profile.submit_button_clicked";
}

class PromoCodeEvents {
  readonly copyButtonClicked = "promo_code.copy_button_clicked";
  readonly shareButtonClicked = "promo_code.share_button_clicked";
  readonly withdrawButtonClicked = "promo_code.withdraw_button_clicked";
}

class InviteEvents {
  readonly copyLinkButtonClicked = "invite.copy_link_button_clicked";
  readonly shareLinkButtonClicked = "invite.share_link_button_clicked";
  readonly downloadQrcCodeButtonClicked =
    "invite.download_qr_code_button_clicked";
}

class PaymentPlansEvents {
  /**
   * @param tab
   */
  readonly tabClicked = "payment_plans.tab_clicked";

  /**
   * @param id
   * @param name
   * @param duration
   * @param price
   * @param trail
   * @param battery_multiplier
   * @param has_active_subscription
   */
  readonly planCardClicked = "payment_plans.plan_card_clicked";
  /**
   * @param id
   * @param name
   * @param duration
   * @param price
   * @param trail
   * @param battery_multiplier
   * @param has_active_subscription
   */
  readonly planCardButtonClicked = "payment_plans.plan_card_button_clicked";
}

class PaymentHistoryEvents {
  readonly buySparkButtonClicked = "order_history.buy_spark_button_clicked";
  /**
   * @param order_id
   */
  readonly copyOrderIdClicked = "order_history.copy_order_id_clicked";
  /**
   * @param order_id
   */
  readonly payButtonClicked = "order_history.pay_button_clicked";
}

class PaymentCheckoutEvents {
  readonly backButtonClicked = "payment_checkout.back_button_clicked";
  readonly discountInputClicked = "payment_checkout.discount_input_clicked";
  /**
   * @param discount_code
   */
  readonly applyDiscountButtonClicked =
    "payment_checkout.apply_discount_button_clicked";
  readonly removeDiscountButtonClicked =
    "payment_checkout.remove_discount_button_clicked";
  /**
   * @param subscription_id
   * @param subscription_name
   * @param subscription_duration
   * @param subscription_price
   * @param subscription_trail
   * @param subscription_battery_multiplier
   * @param total_price
   * @param off_code
   * @param discount_id
   * @param promo_code_id
   */
  readonly payButtonClicked = "payment_checkout.pay_button_clicked";
}

class PaymentResultEvents {
  /**
   * @param order_id
   */
  readonly copyOrderIdClicked = "payment_result.copy_order_id_clicked";
  readonly failureReturnToHomeButtonClicked =
    "payment_result.failure_return_to_home_button_clicked";
  readonly inviteFriendsButtonClicked =
    "payment_result.invite_friends_button_clicked";
  readonly successReturnToHomeButtonClicked =
    "payment_result.success_return_to_home_button_clicked";
}

class SupportEvents {
  readonly linkClicked = "support.link_clicked";
}

class LogOutDialogEvents {
  readonly confirmButtonClicked = "log_out.dialog_confirm_button_clicked";
  readonly cancelButtonClicked = "log_out.dialog_cancel_button_clicked";
}

class MatchMakingEvents {
  /**
   * @param gender
   * @param battery_charge
   * @param has_enough_charge
   * @param is_cool_down_over
   * @param cool_down_end_in_seconds
   * @param last_success_match_date
   */
  readonly started = "match_making.started";
  readonly cancelButtonClicked = "match_making.cancel_button_clicked";
  readonly cancelConfirmationDialogConfirmButtonClicked =
    "match_making.cancel_confirmation_dialog_confirm_button_clicked";
  readonly cancelConfirmationDialogCancelButtonClicked =
    "match_making.cancel_confirmation_dialog_cancel_button_clicked";
  readonly noMatchFoundDialogOkButtonClicked =
    "match_making.no_match_found_dialog_ok_button_clicked";
  /**
   * @param room_id
   */
  readonly matchFoundDialogStartChatButtonClicked =
    "match_making.match_found_dialog_start_chat_button_clicked";
  readonly noSparkDialogChargeBatteryLinkClicked =
    "match_making.no_spark_dialog_charge_battery_link_clicked";
  readonly noSparkDialogBuySparkButtonClicked =
    "match_making.no_spark_dialog_buy_spark_button_clicked";
  readonly noSparkDialogMyBatteryButtonClicked =
    "match_making.no_spark_dialog_my_battery_button_clicked";
}

class ChatsEvents {
  /**
   * @param room_id
   */
  readonly chatRoomClicked = "chats.chat_room_clicked";
}

export abstract class AnalyticsEvents {
  static readonly join = new JoinEvents();
  static readonly phone = new PhoneEvents();
  static readonly otp = new OtpEvents();
  static readonly registration = new RegistrationEvents();
  static readonly home = new HomeEvents();
  static readonly appDrawer = new AppDrawerEvents();
  static readonly profile = new ProfileEvents();
  static readonly promoCode = new PromoCodeEvents();
  static readonly invite = new InviteEvents();
  static readonly paymentPlans = new PaymentPlansEvents();
  static readonly paymentHistory = new PaymentHistoryEvents();
  static readonly paymentCheckout = new PaymentCheckoutEvents();
  static readonly paymentResult = new PaymentResultEvents();
  static readonly support = new SupportEvents();
  static readonly logOutDialog = new LogOutDialogEvents();
  static readonly matchMaking = new MatchMakingEvents();
  static readonly chats = new ChatsEvents();
}
