import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeSvgIcon } from "../../../core/core";
import Logo from "/logo.png";

export function PrivacyPage() {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center justify-center shadow-md">
        <img src={Logo} alt="Fandogh Logo" width={42} />
        <h1 className="p-4 text-3xl" style={{ textAlign: "center" }}>
          فندق
        </h1>
      </div>

      <div className="flex-grow overflow-scroll">
        <div className="p-6 pt-0 max-w-screen-md text-justify m-auto flex flex-col items-stretch leading-relaxed text-gray-800">
          <div className="flex flex-row items-center text-gray-900 my-6">
            <FontAwesomeSvgIcon icon={faShieldHalved} size="2xl" />
            <h1 className="pr-4 text-2xl font-bold">سیاست حفظ حریم خصوصی</h1>
          </div>

          <h2 className="text-xl font-semibold text-gray-700 mb-2">مقدمه</h2>
          <p className="text-gray-700 mb-4 leading-relaxed">
            حریم خصوصی شما برای ما در فندق از اهمیت بالایی برخوردار است. این
            سیاست حفظ حریم خصوصی نحوه جمع‌آوری، استفاده و محافظت از اطلاعات شخصی
            شما را هنگام استفاده از پلتفرم ما شرح می‌دهد. با استفاده از فندق،
            شما با شرایط این سیاست حفظ حریم خصوصی موافقت می‌نمایید.
          </p>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              1. اطلاعاتی که جمع‌آوری می‌کنیم
            </h2>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              1.1. اطلاعات ارائه شده هنگام ثبت‌نام:
            </h3>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">شماره تلفن (برای احراز هویت)</li>
              <li className="mb-1">نام (اجباری)</li>
              <li className="mb-1">نام خانوادگی</li>
              <li className="mb-1">نام مستعار</li>
              <li className="mb-1">تاریخ تولد</li>
              <li className="mb-1">جنسیت</li>
              <li className="mb-1">آواتار انتخابی</li>
              <li className="mb-1">نحوه آشنایی با فندق</li>
            </ol>

            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              1.2. اطلاعات مربوط به استفاده از پلتفرم:
            </h3>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">تاریخ و زمان ورود و خروج از برنامه</li>
              <li className="mb-1">مدت زمان استفاده از برنامه</li>
              <li className="mb-1">
                اطلاعات مربوط به اتصال به سایر کاربران (جنسیت انتخابی، استفاده
                از گزینه شانسی)
              </li>
              <li className="mb-1">
                تاریخچه چت‌ها (محتوای پیام‌ها به صورت خصوصی بین شما و طرف مقابل
                باقی می‌ماند و ما به طور فعال آن‌ها را نظارت نمی‌کنیم، اما در
                صورت گزارش تخلف ممکن است بررسی شوند)
              </li>
              <li className="mb-1">
                اطلاعات مربوط به تراکنش‌ها (خرید اشتراک، دریافت کمیسیون)
              </li>
              <li className="mb-1">
                اطلاعات مربوط به دعوت دوستان و دریافت استار
              </li>
              <li className="mb-1">اطلاعات مربوط به استفاده از کد تخفیف</li>
              <li className="mb-1">
                آدرس IP و اطلاعات دستگاه شما (به منظور بهبود عملکرد و امنیت
                پلتفرم)
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              2. نحوه استفاده از اطلاعات شما
            </h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              ما از اطلاعات جمع‌آوری شده برای اهداف زیر استفاده می‌کنیم:
            </p>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              2.1. ارائه و بهبود خدمات:
            </h3>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">ایجاد و مدیریت حساب کاربری شما</li>
              <li className="mb-1">احراز هویت و حفظ امنیت حساب شما</li>
              <li className="mb-1">امکان اتصال شما به سایر کاربران</li>
              <li className="mb-1">ارائه امکانات چت، حذف و ویرایش پیام‌ها</li>
              <li className="mb-1">
                نمایش وضعیت آنلاین و آخرین بازدید کاربران
              </li>
              <li className="mb-1">مدیریت سیستم شارژ باتری و اشتراک‌ها</li>
              <li className="mb-1">بهبود عملکرد و تجربه کاربری پلتفرم</li>
              <li className="mb-1">ارائه پشتیبانی فنی</li>
            </ol>

            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              2.2. ارتباط با شما:
            </h3>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">ارسال پیام‌های سیستمی و اطلاعیه‌ها</li>
              <li className="mb-1">پاسخ به درخواست‌ها و سوالات شما</li>
            </ol>

            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              2.3. اهداف تحلیلی و آماری:
            </h3>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">
                تجزیه و تحلیل نحوه استفاده از پلتفرم برای بهبود خدمات
              </li>
              <li className="mb-1">تهیه گزارش‌های آماری غیرشخصی</li>
            </ol>

            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              2.4. جلوگیری از سوء استفاده و تخلف:
            </h3>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">
                شناسایی و جلوگیری از فعالیت‌های غیرقانونی و مغایر با قوانین و
                مقررات
              </li>
              <li className="mb-1">بررسی گزارش‌های تخلف کاربران</li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              3. به اشتراک گذاری اطلاعات شما
            </h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              ما به طور کلی اطلاعات شخصی شما را با اشخاص ثالث به اشتراک
              نمی‌گذاریم، مگر در موارد زیر:
            </p>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">
                <strong className="font-semibold">با رضایت شما:</strong> در
                صورتی که رضایت صریح شما برای به اشتراک گذاری اطلاعات وجود داشته
                باشد.
              </li>
              <li className="mb-1">
                <strong className="font-semibold">ارائه دهندگان خدمات:</strong>{" "}
                ما ممکن است اطلاعات غیرشخصی و کلی را با ارائه دهندگان خدمات
                تحلیلی و فنی به اشتراک بگذاریم تا به ما در بهبود خدمات کمک کنند.
                این ارائه دهندگان موظف به حفظ محرمانگی اطلاعات هستند.
              </li>
              <li className="mb-1">
                <strong className="font-semibold">اجرای قانون:</strong> در صورتی
                که طبق قوانین و مقررات جاری کشور ملزم به افشای اطلاعات شخصی شما
                باشیم (مانند دستور قضایی).
              </li>
              <li className="mb-1">
                <strong className="font-semibold">
                  حفاظت از حقوق و ایمنی:
                </strong>{" "}
                در صورتی که باور داشته باشیم افشای اطلاعات برای محافظت از حقوق،
                ایمنی یا دارایی‌های فندق یا سایر کاربران ضروری است.
              </li>
              <li className="mb-1">
                <strong className="font-semibold">
                  در صورت ادغام یا واگذاری:
                </strong>{" "}
                در صورت ادغام، تملک یا فروش کلی یا بخشی از دارایی‌های فندق،
                اطلاعات کاربران ممکن است به عنوان بخشی از دارایی‌های منتقل شده
                در نظر گرفته شود. در این صورت، سیاست حفظ حریم خصوصی جدید به
                اطلاع شما خواهد رسید.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              4. امنیت اطلاعات شما
            </h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              ما تمام تلاش خود را برای محافظت از اطلاعات شخصی شما در برابر
              دسترسی غیرمجاز، استفاده نادرست، تغییر یا از بین رفتن به کار
              می‌گیریم. اقدامات امنیتی ما شامل موارد زیر است:
            </p>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">
                استفاده از پروتکل‌های امنیتی استاندارد برای انتقال و ذخیره‌سازی
                اطلاعات.
              </li>
              <li className="mb-1">
                محدود کردن دسترسی به اطلاعات شخصی به کارکنان مجاز.
              </li>
              <li className="mb-1">
                بررسی و به‌روزرسانی مداوم سیستم‌های امنیتی.
              </li>
            </ol>
            <p className="text-gray-700 mb-4 leading-relaxed">
              با این حال، هیچ روش انتقال داده از طریق اینترنت یا روش ذخیره‌سازی
              الکترونیکی 100% امن نیست. بنابراین، ما نمی‌توانیم امنیت مطلق
              اطلاعات شما را تضمین کنیم.
            </p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              5. حقوق شما در رابطه با اطلاعات شخصیتان
            </h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              شما حقوق زیر را در رابطه با اطلاعات شخصی خود دارید:
            </p>
            <ol className="list-decimal list-inside mb-4 pr-4 text-gray-700 leading-relaxed">
              <li className="mb-1">
                <strong className="font-semibold">حق دسترسی:</strong> می‌توانید
                درخواست دسترسی به اطلاعات شخصی خود را داشته باشید.
              </li>
              <li className="mb-1">
                <strong className="font-semibold">حق اصلاح:</strong> می‌توانید
                درخواست اصلاح اطلاعات نادرست یا ناقص خود را داشته باشید (به جز
                اطلاعات غیرقابل تغییر).
              </li>
              <li className="mb-1">
                <strong className="font-semibold">حق حذف:</strong> در شرایط خاص،
                می‌توانید درخواست حذف اطلاعات شخصی خود را داشته باشید.
              </li>
              <li className="mb-1">
                <strong className="font-semibold">حق اعتراض:</strong> می‌توانید
                به پردازش اطلاعات شخصی خود در شرایط خاص اعتراض کنید.
              </li>
            </ol>
            <p className="text-gray-700 mb-4 leading-relaxed">
              برای اعمال حقوق خود، لطفاً از طریق راه‌های ارتباطی موجود در پلتفرم
              با ما تماس بگیرید.
            </p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              6. مدت زمان نگهداری اطلاعات شما
            </h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              ما اطلاعات شخصی شما را تا زمانی که حساب کاربری شما فعال است و یا
              تا زمانی که برای ارائه خدمات و اهداف ذکر شده در این سیاست حفظ حریم
              خصوصی ضروری باشد، نگهداری خواهیم کرد. پس از آن، اطلاعات شما حذف یا
              ناشناس خواهد شد، مگر اینکه نگهداری آن‌ها برای رعایت الزامات قانونی
              ضروری باشد.
            </p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              7. تغییرات در سیاست حفظ حریم خصوصی
            </h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              ما ممکن است هر از چند گاهی این سیاست حفظ حریم خصوصی را به‌روزرسانی
              کنیم. هرگونه تغییر در این سیاست از طریق پلتفرم به اطلاع شما خواهد
              رسید. ادامه استفاده شما از فندق پس از اعمال تغییرات، به منزله
              پذیرش سیاست حفظ حریم خصوصی جدید خواهد بود. توصیه می‌کنیم به طور
              دوره‌ای این صفحه را برای اطلاع از آخرین تغییرات مرور کنید.
            </p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              8. تماس با ما
            </h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              در صورت داشتن هرگونه سوال یا ابهام در مورد این سیاست حفظ حریم
              خصوصی یا نحوه برخورد ما با اطلاعات شخصی شما، می‌توانید از طریق
              راه‌های ارتباطی موجود در پلتفرم با ما تماس بگیرید.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
