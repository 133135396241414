export abstract class Config {
  public static featureRequestUrl: string =
    "https://docs.google.com/forms/d/e/1FAIpQLSe37XhxvbJvaaOKK5QQtf5WezwKt-ZVP_FhTDSIzphKpuW5hw/viewform?usp=header";

  public static bugReportUrl: string =
    "https://docs.google.com/forms/d/e/1FAIpQLSeFFiWgJJnjkS7Qa0kQWp3k97_TNxz0_3h7Ou5JjBTsN-Q2FA/viewform?usp=header";

  public static xUrl: string = "https://x.com/fandoghchat";

  public static instagramUrl: string = "https://instagram.com/fandogh.chat";

  public static youtubeUrl: string = "https://www.youtube.com/@fandogh-chat";

  public static telegramUrl: string = "https://t.me/fandoghorg";

  public static telegramSupportUrl: string = "https://t.me/fandogh_support";

  public static webAppUrl: string = "https://fandogh.org";

  public static supportEmailAddress: string = "fandoghchat+support@gmail.com";
}
