import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { Config, FontAwesomeSvgIcon } from "../../../core/core";
import Logo from "/logo.png";
import { Link } from "@heroui/react";

export function TermsPage() {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center justify-center shadow-md">
        <img src={Logo} alt="Fandogh Logo" width={42} />
        <h1 className="p-4 text-3xl" style={{ textAlign: "center" }}>
          فندق
        </h1>
      </div>
      <div className="flex-grow overflow-scroll">
        <div className="p-6 pt-0 max-w-screen-md text-justify m-auto flex flex-col items-stretch leading-relaxed">
          <div className="flex flex-row items-center text-gray-900 my-6">
            <FontAwesomeSvgIcon icon={faListCheck} size="2xl" />
            <h1 className="pr-4 text-2xl font-bold">قوانین و مقررات</h1>
          </div>

          <h2 className="text-xl font-semibold text-gray-700 mb-2">مقدمه</h2>
          <p className="text-gray-700 mb-4">
            با تشکر از انتخاب پلتفرم چت ناشناس فندق. این قوانین و مقررات، شرایط
            استفاده شما از خدمات ما را تعیین می‌کنند. لطفاً این سند را به دقت
            مطالعه کنید. با ثبت‌نام و استفاده از فندق، شما موافقت خود را با این
            قوانین و مقررات اعلام می‌نمایید.
          </p>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              1. حساب کاربری
            </h2>
            <ol className="list-decimal list-inside text-gray-700 pr-4">
              <li className="mb-2">
                <span className="font-semibold">ثبت‌نام:</span> برای استفاده از
                فندق، شما باید با شماره تلفن خود ثبت‌نام کنید و کد OTP ارسال شده
                را وارد نمایید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">اطلاعات کاربری:</span> در صورت
                عدم ثبت‌نام قبلی، شما ملزم به ارائه اطلاعات زیر هستید:
                <ul className="list-disc list-inside mt-1 pr-4">
                  <li>نام</li>
                  <li>نام خانوادگی</li>
                  <li>نام مستعار</li>
                  <li>تاریخ تولد</li>
                  <li>جنسیت</li>
                  <li>انتخاب آواتار از موارد موجود</li>
                  <li>نحوه آشنایی با فندق</li>
                </ul>
              </li>
              <li className="mb-2">
                <span className="font-semibold">صحت اطلاعات:</span> شما مسئول
                صحت اطلاعات ارائه شده در هنگام ثبت‌نام و ویرایش‌های بعدی هستید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">عدم تغییر اطلاعات حساس:</span>{" "}
                توجه داشته باشید که شماره تلفن، جنسیت و تاریخ تولد شما پس از
                ثبت‌نام قابل تغییر نخواهند بود.
              </li>
              <li className="mb-2">
                <span className="font-semibold">امنیت حساب:</span> شما مسئول حفظ
                امنیت حساب کاربری خود هستید و نباید اطلاعات ورود خود را در
                اختیار دیگران قرار دهید.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              2. سیستم شارژ باتری
            </h2>
            <ol className="list-decimal list-inside text-gray-700 pr-4">
              <li className="mb-2">
                <span className="font-semibold">نیاز به شارژ:</span> برای اتصال
                به کاربران دیگر و شروع چت، حساب کاربری شما نیاز به شارژ باتری
                دارد.
              </li>
              <li className="mb-2">
                <span className="font-semibold">شارژ اولیه:</span> پس از
                ثبت‌نام، حساب شما به صورت پیشفرض دارای 5 واحد شارژ باتری خواهد
                بود.
              </li>
              <li className="mb-2">
                <span className="font-semibold">شارژ رایگان روزانه:</span> شما
                می‌توانید هر 24 ساعت یک بار باتری خود را به صورت رایگان شارژ
                کنید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">هزینه اتصال:</span>
                <ul className="list-disc list-inside mt-1 pr-4">
                  <li>
                    انتخاب جنسیت هنگام اتصال: 2 واحد شارژ باتری کسر می‌شود.
                  </li>
                  <li>استفاده از گزینه شانسی: 1 واحد شارژ باتری کسر می‌شود.</li>
                </ul>
              </li>
              <li className="mb-2">
                <span className="font-semibold">شارژ با استار:</span> در صورت
                داشتن "استار" (توضیحات در بخش 5)، می‌توانید بدون توجه به زمان
                باقی‌مانده از شارژ رایگان روزانه، باتری خود را به صورت کامل شارژ
                کنید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">خرید اشتراک:</span> برای افزایش
                ظرفیت شارژ باتری، می‌توانید اشتراک‌های مختلف را خریداری کنید. به
                عنوان مثال، یک اشتراک ماهانه می‌تواند ظرفیت باتری شما را دو
                برابر کند.
              </li>
              <li className="mb-2">
                <span className="font-semibold">پایان اشتراک:</span> با پایان
                دوره اشتراک، ظرفیت باتری شما به مقدار اولیه (5 واحد) باز خواهد
                گشت.
              </li>
              <li className="mb-2">
                <span className="font-semibold">
                  عدم امکان خرید اشتراک همزمان:
                </span>{" "}
                در صورتی که یک اشتراک فعال داشته باشید، امکان خرید اشتراک جدید
                وجود ندارد.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              3. محتوای تولید شده توسط کاربر
            </h2>
            <ol className="list-decimal list-inside text-gray-700 pr-4">
              <li className="mb-2">
                <span className="font-semibold">مسئولیت محتوا:</span> شما مسئول
                محتوایی هستید که در چت‌ها ارسال می‌کنید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">ممنوعیت محتوای نامناسب:</span>{" "}
                ارسال هرگونه محتوای غیرقانونی، غیراخلاقی، توهین‌آمیز، تهدیدآمیز،
                آزاردهنده، مبتذل، تبعیض‌آمیز، یا نقض کننده حقوق دیگران اکیداً
                ممنوع است.
              </li>
              <li className="mb-2">
                <span className="font-semibold">
                  عدم انتشار اطلاعات شخصی دیگران:
                </span>{" "}
                شما مجاز به انتشار اطلاعات شخصی دیگر کاربران بدون رضایت آن‌ها
                نیستید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">حق حذف محتوا:</span> فندق این حق
                را برای خود محفوظ می‌دارد که هرگونه محتوایی را که مغایر با این
                قوانین و مقررات تشخیص دهد، بدون اطلاع قبلی حذف نماید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">تبلیغات:</span>‌ ارسال هرگونه
                تبلیغات، محتوای تجاری، یا درخواست‌های مالی به هر شکلی (شامل
                لینک‌ها، تصاویر، متن و غیره) در چت‌ها و سایر بخش‌های پلتفرم
                اکیداً ممنوع است.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              4. سیستم دعوت دوستان و دریافت استار
            </h2>
            <ol className="list-decimal list-inside text-gray-700 pr-4">
              <li className="mb-2">
                <span className="font-semibold">لینک دعوت:</span> هر کاربر دارای
                یک لینک دعوت مخصوص به خود است که می‌تواند آن را با دوستان خود به
                اشتراک بگذارد.
              </li>
              <li className="mb-2">
                <span className="font-semibold">دریافت استار:</span> به ازای هر
                سه کاربری که از طریق لینک دعوت شما در فندق ثبت‌نام کنند، شما یک
                عدد "استار" دریافت خواهید کرد.
              </li>
              <li className="mb-2">
                <span className="font-semibold">کاربرد استار:</span> در حال
                حاضر، کاربرد استار، امکان شارژ کامل باتری بدون در نظر گرفتن زمان
                باقی‌مانده از شارژ رایگان روزانه است. فندق ممکن است در آینده
                کاربردهای دیگری برای استار تعریف کند.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              5. سیستم کد تخفیف و کسب درآمد
            </h2>
            <ol className="list-decimal list-inside text-gray-700 pr-4">
              <li className="mb-2">
                <span className="font-semibold">کد تخفیف اختصاصی:</span> هر
                کاربر دارای یک کد تخفیف اختصاصی است.
              </li>
              <li className="mb-2">
                <span className="font-semibold">کسب درآمد از اشتراک کد:</span>{" "}
                شما می‌توانید کد تخفیف خود را با دیگران به اشتراک بگذارید. در
                صورتی که خریدی با استفاده از کد شما انجام شود، شما درصدی کمیسیون
                دریافت خواهید کرد.
              </li>
              <li className="mb-2">
                <span className="font-semibold">
                  استفاده از کد تخفیف دیگران:
                </span>{" "}
                هنگام خرید اشتراک، می‌توانید از کد تخفیف دیگر کاربران (به شرطی
                که قبلاً از آن استفاده نکرده باشید و کد متعلق به خودتان نباشد)
                استفاده کنید. در این صورت، شما تخفیف دریافت خواهید کرد و کاربر
                صاحب کد، کمیسیون کسب خواهد کرد.
              </li>
              <li className="mb-2">
                <span className="font-semibold">
                  حداقل مبلغ برای برداشت کمیسیون:
                </span>{" "}
                برای برداشت درآمد حاصل از کمیسیون‌ها، باید حداقل 100,000 تومان
                کمیسیون جمع‌آوری کرده باشید.
              </li>
              <li className="mb-2">
                <span className="font-semibold">دوره انتظار برای برداشت:</span>{" "}
                به محض ثبت‌نام هر کاربر، یک تایمر ۳۰ روزه برای برداشت درآمد او
                فعال می‌شود. اگر بعد از گذشت این ۳۰ روز، میزان کمیسیون کاربر به
                حداقل مبلغ برای برداشت رسیده باشد، می‌تواند درخواست برداشت ثبت
                کند.
              </li>
              <li className="mb-2">
                <span className="font-semibold">محدودیت زمانی برداشت:</span> پس
                از ثبت درخواست برداشت، یک تایمر 30 روزه برای برداشت مجدد فعال
                می‌شود و تا پایان این دوره، امکان ثبت درخواست برداشت جدید وجود
                نخواهد داشت.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              6. مالکیت معنوی
            </h2>
            <p className="text-gray-700">
              کلیه حقوق مالکیت معنوی مربوط به پلتفرم فندق، شامل طراحی، کدنویسی،
              محتوا و سایر موارد، متعلق به فندق می‌باشد و هرگونه استفاده غیرمجاز
              از آن‌ها پیگرد قانونی خواهد داشت.
            </p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              7. سلب مسئولیت
            </h2>
            <ol className="list-decimal list-inside text-gray-700 pr-4">
              <li className="mb-2">
                فندق یک پلتفرم چت ناشناس است و هیچ مسئولیتی در قبال رفتار، گفتار
                و محتوای تولید شده توسط کاربران ندارد.
              </li>
              <li className="mb-2">
                استفاده از فندق بر عهده خود شماست و شما مسئول تعاملات خود با
                سایر کاربران هستید.
              </li>
              <li className="mb-2">
                فندق هیچ گونه تضمینی در مورد هویت، سن، جنسیت یا سایر اطلاعات
                ارائه شده توسط کاربران نمی‌دهد.
              </li>
            </ol>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              8. تغییرات در قوانین و مقررات
            </h2>
            <p className="text-gray-700">
              فندق این حق را برای خود محفوظ می‌دارد که در هر زمان و بدون اطلاع
              قبلی، این قوانین و مقررات را تغییر دهد. ادامه استفاده شما از فندق
              پس از اعمال تغییرات، به منزله پذیرش قوانین و مقررات جدید خواهد
              بود.
            </p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              9. قطع دسترسی
            </h2>
            <p className="text-gray-700">
              فندق این حق را برای خود محفوظ می‌دارد که در صورت نقض این قوانین و
              مقررات، یا به هر دلیل دیگری به تشخیص خود، دسترسی شما به پلتفرم را
              به طور موقت یا دائم قطع نماید.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              10. ارتباط با ما
            </h2>
            <p className="text-gray-700">
              در صورت داشتن هرگونه سوال یا ابهام در مورد این قوانین و مقررات،
              می‌توانید از طریق راه‌های ارتباطی موجود در پلتفرم با ما تماس
              بگیرید.
            </p>
          </div>

          <p className="py-4">
            <span className="font-semibold text-gray-700">
              ایمیل پشتیبانی:{" "}
            </span>
            <Link href={`mailto:${Config.supportEmailAddress}`}>
              {Config.supportEmailAddress}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
