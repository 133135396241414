import { useCallback, useEffect, useMemo } from "react";
import {
  Config,
  copyTextToClipboard,
  FailureView,
  FontAwesomeSvgIcon,
  promoCodeRepository,
  Timer,
  toPersianDigits,
  toPersianPrice,
  useAsyncValue,
  useStore,
} from "../../../core/core";
import { Button, Spinner } from "@heroui/react";
import {
  faShareNodes,
  faCopy,
  faTags,
  faSackDollar,
  faUsers,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

export function PromoCode() {
  const [state, asyncValueController] = useAsyncValue({
    getData: useCallback(() => promoCodeRepository.getOwnCode(), []),
  });

  useEffect(() => {
    asyncValueController.invoke(null);
  }, [asyncValueController]);

  const promoCode = useMemo(
    () =>
      state.maybeFold({
        orElse: () => "",
        loadSuccess: (state) => state.code,
      }),
    [state]
  );

  const doesSupportShareAPI = useMemo(() => !!navigator.share, []);

  const copyPromoCode = useCallback(() => {
    copyTextToClipboard(promoCode);
    toast.info("کد تخفیف با موفقیت کپی شد!");
  }, [promoCode]);

  const onShareButtonClicked = useCallback(() => {
    const text = `چت ناشناس و سرگرم‌کننده با فندق!\nاگه هنوز ثبت‌نام نکردی، همین حالا از لینک زیر ثبت‌نام کن و ۲٪ تخفیف بگیر:\n${Config.webAppUrl}\nپس از ثبت‌نام، با وارد کردن کد تخفیف زیر، ۲٪ تخفیف برای خریدت دریافت می‌کنی:\n\nکد تخفیف: ${promoCode}\n\nهمین الان از این فرصت استفاده کن! 😎`;

    if (doesSupportShareAPI) {
      const canShare = navigator.canShare({ text });

      if (canShare) {
        navigator.share({ text });
      } else {
        copyPromoCode();
      }
    } else {
      copyPromoCode();
    }
  }, [copyPromoCode, doesSupportShareAPI, promoCode]);

  const walletsState = useStore((state) => state.wallets);
  const getWallets = useStore((state) => state.getWallets);
  useEffect(() => {
    walletsState.maybeFold({
      orElse: getWallets,
      loadSuccess: () => {},
      loadInProgress: () => {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWallets]);
  const irtc = useMemo(
    () =>
      walletsState.maybeFold({
        orElse: () => 0,
        loadSuccess: (wallets) =>
          Number(
            wallets?.filter((wallet) => wallet.currency === "IRTC")[0].balance
          ) / 10,
      }),
    [walletsState]
  );

  const nextWithdrawalDate = useMemo(
    () =>
      state.maybeFold({
        orElse: () => null,
        loadSuccess: (state) =>
          new Date(
            state.lastWithdrawalAt.getTime() +
              state.withdrawalDuration * 24 * 60 * 60 * 1000
          ),
      }),
    [state]
  );

  const balanceNeededToWithdraw = useMemo(
    () =>
      state.maybeFold({
        orElse: () => 0,
        loadSuccess: (state) => {
          const amountNeeded = state.withdrawalAmount / 10 - irtc;

          if (amountNeeded < 0) {
            return 0;
          }

          return amountNeeded;
        },
      }),
    [irtc, state]
  );

  return (
    <>
      {state.maybeFold({
        orElse: () => <Spinner />,
        loadFailure: (failure) => (
          <FailureView
            failure={failure}
            onRetry={() => asyncValueController.invoke(null)}
          />
        ),
        loadSuccess: (state) =>
          walletsState.maybeFold({
            orElse: () => <Spinner />,
            loadFailure: (failure) => (
              <FailureView failure={failure} onRetry={getWallets} />
            ),
            loadSuccess: () => (
              <div>
                <div className="flex flex-row items-center">
                  <FontAwesomeSvgIcon icon={faTags} size="lg" />
                  <h1 className="mr-3 text-xl font-medium">
                    کد تخفیف و درآمد شما
                  </h1>
                </div>
                <p className="py-2">
                  کدتو با دیگران به اشتراک بذار! اونا با وارد کردن این کد موقع
                  خرید،{" "}
                  <span className="font-semibold">
                    {toPersianDigits(state.discountPercent.toString())}٪
                  </span>{" "}
                  تخفیف می‌گیرن و تو هم{" "}
                  <span className="font-semibold">
                    {toPersianDigits(state.commissionPercent.toString())}٪
                  </span>{" "}
                  پورسانت از خریدشون به دست میاری!
                </p>
                <div className="bg-gray-100 text-center text-gray-700 py-2 px-4 rounded-lg mt-2 mb-4">
                  <h1 className="text-lg font-mono">{state.code}</h1>
                </div>
                <div className="flex flex-col sm:flex-row justify-center items-center gap-3">
                  <Button
                    fullWidth
                    variant="bordered"
                    color="primary"
                    startContent={<FontAwesomeSvgIcon icon={faCopy} />}
                    onPress={() => {
                      logAnalyticsEvent(
                        AnalyticsEvents.promoCode.copyButtonClicked
                      );
                      copyPromoCode();
                    }}
                  >
                    کپی کد
                  </Button>
                  <Button
                    fullWidth
                    variant="bordered"
                    color="primary"
                    startContent={<FontAwesomeSvgIcon icon={faShareNodes} />}
                    onPress={() => {
                      logAnalyticsEvent(
                        AnalyticsEvents.promoCode.shareButtonClicked
                      );
                      onShareButtonClicked();
                    }}
                  >
                    اشتراک گذاری کد
                  </Button>
                </div>
                <div className="flex flex-row gap-3 mt-3">
                  <div
                    className="flex-1"
                    style={{
                      background:
                        "linear-gradient(135deg, rgb(0, 111, 238), rgb(120, 40, 200))",
                      borderRadius: 12,
                      padding: 16,
                      color: "white",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <FontAwesomeSvgIcon icon={faSackDollar} size="xl" />
                      <h1 className="mr-3 text-3xl font-medium">
                        {toPersianPrice(irtc.toString())}{" "}
                        <span className="text-lg font-normal">تومان</span>
                      </h1>
                    </div>

                    <h1 className="mt-4">درآمد قابل برداشت جمع کردی!</h1>
                  </div>

                  <div
                    className="flex-1"
                    style={{
                      background:
                        "linear-gradient(135deg, rgb(0, 111, 238), rgb(120, 40, 200))",
                      borderRadius: 12,
                      padding: 16,
                      color: "white",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <FontAwesomeSvgIcon icon={faUsers} size="xl" />
                      <h1 className="mr-3 text-3xl font-medium">
                        {toPersianDigits(state.used.toString())}{" "}
                        <span className="text-lg font-normal">نفر</span>
                      </h1>
                    </div>

                    <h1 className="mt-4">تا حالا از کدت استفاده کردن!</h1>
                  </div>
                </div>

                <div className="my-3 border-1 border-secondary rounded-lg">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="flex-1 text-3xl flex flex-col items-center py-3">
                      <Timer end={nextWithdrawalDate!} />
                      <h1 className="text-sm">تا برداشت بعدی</h1>
                    </div>

                    <div className="flex-1 text-3xl flex flex-col items-center py-3">
                      {balanceNeededToWithdraw === 0 ? (
                        <h1 className="text-sm text-center">
                          به حداقل درآمد قابل برداشت رسیدی!
                        </h1>
                      ) : (
                        <>
                          <h1>
                            {toPersianPrice(balanceNeededToWithdraw.toString())}{" "}
                            <span className="text-sm">تومان</span>
                          </h1>
                          <h1 className="text-sm text-center">
                            می‌خوای تا بتونی پولتو برداری!
                          </h1>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="m-3 mt-2">
                    <Button
                      fullWidth
                      variant="solid"
                      color="secondary"
                      startContent={
                        <FontAwesomeSvgIcon icon={faMoneyBillTransfer} />
                      }
                      // TODO: Read from api
                      isDisabled
                      // TODO: When we add withdrawal feature
                      onPress={() => {
                        logAnalyticsEvent(
                          AnalyticsEvents.promoCode.withdrawButtonClicked
                        );
                      }}
                    >
                      برداشت درآمد
                    </Button>
                  </div>
                </div>

                <ul className="list-disc pr-4 text-sm">
                  <li>
                    برای برداشت درآمدت، باید حداقل{" "}
                    <span className="font-bold">
                      {toPersianPrice((state.withdrawalAmount / 10).toString())}
                    </span>{" "}
                    تومان درآمد قابل برداشت داشته باشی.
                  </li>
                  <li>
                    درآمدت رو هر{" "}
                    <span className="font-bold">
                      {toPersianDigits(state.withdrawalDuration.toString())}
                    </span>{" "}
                    روز می‌تونی برداشت کنی! وقتی تایمر به صفر برسه، هر وقت
                    خواستی می‌تونی درخواست برداشتت رو ثبت کنی. بعد از برداشت،
                    تایمر دوباره شروع می‌شه و شمارش برای{" "}
                    <span className="font-bold">
                      {toPersianDigits(state.withdrawalDuration.toString())}
                    </span>{" "}
                    روز بعدی آغاز می‌شه.
                  </li>
                </ul>
              </div>
            ),
          }),
      })}
    </>
  );
}
